<template>
 <div class="salesDetailConent">
  <div class="detailTop">
    用户详情
  </div>
  <div class="detailConent">
  <span>企业名称:</span>
  <input type="text" disabled v-model="salesDetailObject.enterpriseName">
  </div>
  <div class="detailConent">
  <span>品牌名:</span>
  <input type="text" disabled v-model="salesDetailObject.brandName">
  </div>
  <div class="detailConent">
  <span>客户姓名:</span>
  <input type="text" disabled v-model="salesDetailObject.customerName">
  </div>
  <div class="detailConent">
  <span>用户手机号:</span>
  <input type="text" disabled v-model="salesDetailObject.customerMobile">
  </div>
  <div class="detailConent">
  <span>代码行业:</span>
  <input type="text" disabled v-model="salesDetailObject.industryName">
  </div>
  <div class="detailConent">
  <span>备注:</span>
  <input type="text" disabled v-model="salesDetailObject.remark">
  </div>
      <!-- 合同 -->
      <van-field name="uploader" label="合同凭证截图">
    <template #input>
      <!--  delete删除按钮触发事件-->
      <van-uploader disabled :deletable="false" v-model="fileContractList" :max-count="20"/>
    </template>
  </van-field>
     <!-- 上传图片 -->
  <van-field name="uploader" label="支付凭证截图">
    <template #input>
      <!--  delete删除按钮触发事件-->
      <van-uploader disabled :deletable="false" v-model="fileList" :max-count="1"/>
    </template>
  </van-field>
 </div>
</template>

<script>
import {  getUserSalesDetail } from '@/api/user/index'
export default {
  data() {
    return {
      salesDetailObject:{
        brandName: '',
        customerMobile: '',
        customerName: '',
        enterpriseName:'',
        payVoucherPic:'',
        status: ''
      },
      fileList:[],
      fileContractList:[],
      businessFileList:[]
    };
  },
  created() {
    this.getUserSalesDetail()
  },
  mounted() {

  },
  methods: {
    /**
    * 代码描述: 获取用户参数接口
    * 作者:liushiban
    * 创建时间:
    */
    getUserSalesDetail(){
      let { userSalesId } = this.$route.params
      getUserSalesDetail(userSalesId).then(res=>{
        if(res.status == '200'){
        let  { accountOpenApplication} = res.data.data
          this.salesDetailObject = accountOpenApplication
          // 处理图片
          if(accountOpenApplication.payVoucherPic){
            this.fileList.push({url:accountOpenApplication.payVoucherPic})
          }else if(JSON.parse(accountOpenApplication.electronicContract).length>0){
            this.fileContractList = JSON.parse(accountOpenApplication.electronicContract)
          }else{
            return true
          }
        }
      }).catch(error=>{
        console.log(error)
      })
    }

  },
};
</script>

<style scoped lang="scss">
.salesDetailConent{
  height: 100%;
  font-size: 14px;
  box-sizing: border-box;
  .detailTop{
    padding: 20px 0;
    font-size: 16px;
    font-weight: 400;
  }
  .detailConent{
    display: flex;
    padding: 10px 20px;
    height: 30px;
    line-height: 30px;
    align-content: center;
    justify-content: center;
    flex-flow: nowrap;
    span{
      flex: 1;
      text-align: right;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */
    }
    input{
      flex: 3;
      padding-left: 10px;
      margin-left: 10px;
      font-size: 14px;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
    }
  }
}

</style>
