import request from '@/untils/request'
//获取用户详情
export function getUserDetail() {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/login/details'
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
//
export function getUserDetailName() {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'GET',
      url: '/saler/detail'
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}


/**
* 代码描述:销售查询开户列表
* 作者:liushiban
* 创建时间:
*/
export function getUserSalesList(query,status) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'GET',
      url: '/application/list',
      params:{
        query: query || '',
        status: status || '100'
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
/**
* 代码描述: 获取用户列表详情参数
* 作者:liushiban
* 创建时间:
*/
export function getUserSalesDetail(UserId) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'GET',
      url: '/application/detail',
      params:{
        accountOpenApplicationId:UserId
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
/**
* 代码描述: 开户申请表单接口
* 作者:liushiban
* 创建时间:
*/
export function setUserDetailInformation(accountOpenApplication) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/application/addApplication',
      data:{
        accountOpenApplication
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
/**
* 代码描述: 开通钱包支付第三方跳转链接 (企业)  会员注册连接
* 作者:liushiban
* 创建时间:
*/
export function getRegInviteLink(bizUserId,companyName) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianMember/getRegInviteLink',
      params:{
        bizUserId,
        companyName
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}